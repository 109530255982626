/* ============ Search box ============= */
.search-box {
    position: relative;
    .icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: hsl(var(--body-color)); 
    }
    &.style-two {
        .common-input {
            padding-right: 80px;
            border-color: transparent;
            &:focus {
                border-color: hsl(var(--main));
            }
        }
        .icon {
            height: 100%;
            width: 70px;
            text-align: center;
            background: transparent;
            color: hsl(var(--white)) !important;
            right: 0 !important;
            border-radius: 0 5px 5px 0;
            z-index: 1;
            &::before, &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: var(--main-gradient);
                transition: 0.2s linear;
                border-radius: inherit;
            }
            &::after {
                background: var(--main-gradient-rev);
                visibility: hidden;
                opacity: 0;
            }
            &:hover, &:focus, &:focus-visible {
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
/* ================== Search Box =================== */ 