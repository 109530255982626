/* ========================= Portfolio Section Css Start ==================== */
.portfolio {
}


.portfolio-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  &:hover {
    .portfolio-item {
        &__content {
            transform: translateY(0%);
            bottom: 0px;
        }
    }
  }
  &__thumb {
    border-radius: inherit;
  }
  &__content {
    width: 100%;
    background-color: var(--gray-900);
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    bottom: -22px;
    transition: .2s linear;
    .btn-icon {
        margin-left: auto;
        right: 40px;
        top: -22px;
        position: absolute;
    }
  }
  &__inner {
    padding: clampCal(16, 32);
    padding-top: 32px;
  }
  &__title {
    color: hsl(var(--white));
    margin-bottom: 14px;
    .link {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &:hover {
            @extend .text-gradient; 
        }
    }
  }
  &__desc {
    color: hsl(var(--white));
    opacity: 0.8;
    font-size: clampCal(15, 18);
  }
}
/* ========================= Portfolio Section Css End ==================== */
