/* ========================= Message Section Css Start ======================== */
.message {
    padding: 80px 0;
    position: relative;
    z-index: 1;
    @include sm-screen {
        padding: 60px 0;
    }
    @include msm-screen {
        padding: 50px 0;
    }
    &-thumb {
        border-radius: 0 5px 5px 0;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 65%;
        height: 100%;
    }
}

/* Form Box Css with border & dark version */
.form-box {
    background-color: hsl(var(--white));
    border-radius: 5px;
    padding: clampCal(24, 60);
    max-width: 660px;
    margin-left: auto;
    box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
    &.style-dark {
        border: 1px solid hsl(var(--white)/.2);
        background-color: var(--gray-700);
        max-width: unset;
        input {
            border-color: hsl(var(--white)/.2);
        }
        label {
            font-family: var(--poppins-font);
        }
    }
    &__title {
        margin-bottom: clampCal(24, 46);
    }
}
/* ========================= Message Section Css End ======================== */