/* ===================== Counter Two Section Css Start ======================= */
.counter-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr)); 
}
.counter-two {
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__number {
            margin-bottom: 6px;
        }
        &__text {
            position: relative;
            padding-left: 50px;
            @media (min-width: 768px) and (max-width: 992px) {
                font-size: 0.875rem !important;
            }
            @include xsm-screen {
                padding-left: 0px;
            }
            &::before {
                position: absolute;
                content: "";
                width: 36px;
                height: .5px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: hsl(var(--white));
                @include xsm-screen {
                    display: none;
                }
            }
        }
    }
}
/* ===================== Counter Two Section Css End ======================= */