/* ========================= Team Section Css Start ============================= */
.team-item {
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    &__name {
        @include msm-screen {
            @include font-14; 
        }
    }
    &__thumb {
        position: relative;
        z-index: 1;
        border: inherit;
        &:hover {
            img {
                transform: scale(1.06);
            }
        }
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            background: linear-gradient(180deg, rgba(24, 22, 22, 0) 0%, #181616 100%);
            border-radius: inherit;
            left: 0;
            bottom: 0;
            z-index: 1;
            height: 50%;
        }
        img {
            transition: .2s linear;
            min-height: 300pxp;
        }
    }    
    &__content {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: clampCal(16, 30);
        z-index: 2;
    }   
}

.social-share {
    position: relative;
    &__button {
        width: 34px;
        height: 34px;
        border-radius: 5px;
        color: hsl(var(--heading-color));        
        background-color: hsl(var(--white));
        transition: .2s linear;
        &.active, &:hover {
            background-color: var(--gray-200);
            i {
                @extend .text-gradient; 
            }
        }
    }
    &-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        bottom: calc(100% + 10px);
        left: 0;
        transition: .2s linear;
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
        &.active {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
        &.style-two {
            position: relative;
            bottom: 0;
            clip-path: unset; 
            flex-direction: row;
        }
        &__link {
            width: 34px;
            height: 34px;
            border-radius: 5px;
            color: hsl(var(--white)) !important;  
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            position: relative;
            &::before, &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: var(--main-gradient);
                transition: 0.2s linear;
                border-radius: inherit;
            }
            &::after {
                background: var(--main-gradient-rev);
                visibility: hidden;
                opacity: 0;
            }
            &:hover, &:focus, &:focus-visible {
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
/* ========================= Team Section Css End ============================= */

