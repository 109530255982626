/* ========================== Banner Five Css Start ========================= */
.banner-five {
    padding-top: 150px;
    padding-bottom: 150px;
    @include md-screen {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    @include sm-screen {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include msm-screen {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .banner-content {
        &__title {
            @media (max-width: 1299px) {
                font-size: 50px;
            }
            @include sm-screen {
                @include font-40; 
            }
            @include msm-screen {
                @include font-32; 
            }
        }
    }
    &__thumb {
        position: relative;
        @media (min-width: 992px) {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 50%;
            height: 100%;
            display: flex;
            z-index: 1;
        }
        &::before {
            position: absolute;
            content: "";
            width: 170px;
            height: 186px;
            background: var(--main-gradient);
            opacity: .3;
            left: 0;
            top: 32px;
            border-radius: 0px 0px 117px 0px;      
            @include msm-screen {
                width: 120px;
                height: 146px;
            }      
        }
        img {
            border-radius: 360px 10px 10px 10px;   
            @media (min-width: 992px) { 
                border-radius: 360px 0px 0px 0px;
            }

        }
    }
}
/* ========================== Banner Five Css End ========================= */