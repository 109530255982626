/* ========================== Gallery Section Css Start =========================== */
.gallery-thumb {
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    height: 100%;
    position: relative;
    &:hover &__link {
        transform: translate(-50%, -50%) scale(1);
        visibility: visible;
        opacity: 1;
    }
    &__link {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(.6);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background-color: hsl(var(--black)/.6);
        color: hsl(var(--white)) !important;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;
    }
}
/* ========================== Gallery Section Css End =========================== */