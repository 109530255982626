/* ================================= Tab Css Start =========================== */
.common-tab {
    margin-bottom: 20px;
    gap: 10px;
    &.style-two {
        .nav-item {
            .nav-link {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                padding: 0 !important;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--gray-900) !important;
                @include font-12; 
                border-color: hsl(var(--white)/.3) !important;
                color: hsl(var(--white)/.3);
                font-weight: 500;
                &::before {
                    background: var(--gray-900);
                }
                &.active {
                    color: hsl(var(--main)) !important;
                    .text {
                        @extend .text-gradient; 
                    }
                }
            }    
        }
    }
    &.style-outline {
     .nav-item {
        .nav-link {
            &.active {
                .text {
                    @extend .text-gradient; 
                }
            }
            &::before {
                background: hsl(var(--white));
            }
        }
     }   
    }
    .nav-item {
        border-bottom: 0;
        .nav-link {
            color: hsl(var(--heading-color)); 
            padding: 7px 25px !important;
            background-color: hsl(var(--white));
            border-radius: 5px;
            transition: .2s linear;
            border: 1px solid var(--border-color) !important;
            position: relative;
            z-index: 1;
            font-weight: 400;
            overflow: hidden;
            @include sm-screen {
                padding: 5px 14px !important;
            }
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: var(--main-gradient);
                visibility: hidden;
                opacity: 0;
                transition: .2s linear;
            }
            &.active {
                &::before {
                    visibility: visible;
                    opacity: 1;
                }
                color: hsl(var(--white)) !important; 
                border-color: hsl(var(--main)) !important;
            }
            &:hover {
                color: hsl(var(--main));
            }
        }
    }
}

/* Side Tab Css Start */
.side-tab {
    .nav-link {
        color: hsl(var(--body-color));
        font-family: var(--poppins-font);
        font-size: 15px;
        text-align: left;
        padding: 12px 24px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: 16px;
        &:hover {
            @extend .text-gradient; 
        }
        &.active {
            background: var(--main-gradient);
            color: hsl(var(--white));
            -webkit-text-fill-color: hsl(var(--white));
        }

        .icon {
            font-size: 14px;
        }
    }
}
/* Side Tab Css End */

/* ================================= Tab Css End =========================== */
