/* ========================== Contact Form Css Start ============================ */
.contact-form {
    padding:  clampCal(40, 80) clampCal(24, 80);
    border-radius: 6px;
    box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
    &__form  {
        max-width: 850px;
        margin: 0 auto;
    }
}

.contact-us-section {
    margin-top: -110px;
    position: relative;
    z-index: 1;
}

/* ========================== Contact Form Css End ============================ */