/* ======================= Project page Section Css Start ====================== */
.project-section {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, var(--gray-100) 0%, hsl(var(--white)) 100%);
        z-index: -1;
    }
    .project-page__inner > div > div .slick-slide:nth-child(even) {
        margin-top: clampCal(24, 50);
    }
    .project-page-thumb {
        overflow: hidden;
        height: clampCal(260, 428) !important;
    }
} 
/* ======================= Project page Section Css End ====================== */