/* =========================== Project Details Css Start =========================== */
.project-details {
    &__thumb {
        max-height: 500px;
        overflow: hidden;
        border-radius: 8px;
    }
    &__content {
        margin-top: clampCal(20, 30);
    }
    &__title {
        margin-bottom: clampCal(20, 30);
    }
    &__desc {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.border-title {
    padding-bottom: 14px;
    margin-top: clampCal(20, 30);
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background: var(--main-gradient);
    }
}

.text-list-two {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    @include msm-screen {
        grid-template-columns: 1fr;
    }
    &__item {
        position: relative;
        padding-left: 18px;
        &::before {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: var(--main-gradient);
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }
}

.project-sidebar {
    &__box {
        background-color: hsl(var(--white));
        box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
        padding: clampCal(24, 40);
        margin-right: 32px;
        margin-top: -90px;
        @include md-screen {
            margin-right: 0px;
            margin-top: 0px;
        }
    }
    &__item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--border-color);
        @include sm-screen {
            padding-bottom: 12px;
            margin-bottom: 12px;
        }
        &:last-child {
            padding-bottom: 0px;
            margin-bottom: 0px;
            border-bottom: 0;
        }
    }
    &__text {
        margin-bottom: 10px;
    }
    &__title {

    }
}

/* =========================== Project Details Css End =========================== */