
/* ================================= Social Icon Css Start =========================== */
.social-list {
    display: inline-flex;
    gap: 24px;
    align-items: center;
    margin-top: 30px;
    border: 1px solid hsl(var(--white)/.15);
    padding: 12px;
    border-radius: 5px;
    &__link {
        color: hsl(var(--white));
        &:hover {
            color: hsl(var(--main)); 
            @extend .text-gradient; 
        }
    }

    &.style-two {
        border: 0;
        padding: 0;
        gap: 6px;
        .social-list {
            &__link {
                width: 65px;
                height: 52px;
                border: 1px solid var(--border-color);
                border-radius: 5px;
                color: hsl(var(--heading-color));
                @include font-24; 
                @include sm-screen {
                    width: 48px;
                    height: 46px;
                }
            }
        }
    }
}
.social-icon-list {
    &.style-two {
        .social-icon-list {
            &__link {
                border-radius: 5px;        
                border-color: hsl(var(--white)/.1);
            }
        }
    }
    &__link {
        width: 30px;
        height: 30px;
        border: 1px solid hsl(var(--white));
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: hsl(var(--white));
        @include font-14; 
        &:hover {
            background-color: hsl(var(--white));
            color: hsl(var(--main));
        }
    }
}

.text {
    &-facebook {
        color: #4676ED !important;
    }
    &-pinterest {
        color: #C40027 !important;
    }
    &-linkedin {
        color: #4467AD !important;
    }
}
/* ================================= Social Icon Css End ===========================  */