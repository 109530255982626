/* ================================= Padding Css Start =========================== */
.padding-y-120 {
  padding-top: 60px;
  padding-bottom: 60px;
  @media (min-width: 576px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.padding-t-120 {
    padding-top: 60px;
    @media (min-width: 576px) {
    padding-top: 80px;
  }
    @media (min-width: 992px) {
    padding-top: 120px;
  }
}
.padding-b-120 {
    padding-bottom: 60px;
    @media (min-width: 576px) {
    padding-bottom: 80px;
  }
    @media (min-width: 992px) {
    padding-bottom: 120px;
  }
}
.padding-y-60 {
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
    @media (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.padding-t-60 {
    padding-top: 30px;
    @media (min-width: 576px) {
    padding-top: 40px;
  }
    @media (min-width: 992px) {
    padding-top: 60px;
  }
}
  .padding-b-60 {
    padding-bottom: 30px;
    @media (min-width: 576px) {
    padding-bottom: 40px;
  }
    @media (min-width: 992px) {
    padding-bottom: 60px;
  }
}
/* ================================= Padding Css End =========================== */
