/* =================== Working Process Section Css Start ========================== */
.working-process {
    position: relative;
    z-index: 1;
    &__shape {
        position: absolute;
        left: 0;
        bottom: 80px;
        z-index: -1;
    }
}

.working-process-item {
    gap: clampCal(24, 50);
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 3px solid var(--border-color);
    position: relative;
    padding-right: 44px;
    @include md-screen {
        margin-bottom: 12px;
        padding-bottom: 12p;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &__number {
        font-weight: 400;
        font-size: clampCal(32, 46);
        color: var(--gray-800);
        margin-bottom: 0;
        flex-shrink: 0;
    }
    &__title {
        margin-bottom: 4px;
    }
    &__link {
        font-size: 50px;
        color: var(--gray-900);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        &:hover {
            color: hsl(var(--main));
            @extend .text-gradient; 
        }
    }
}
/* =================== Working Process Section Css End ========================== */