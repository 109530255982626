
/* ================================= Comment Css Start =========================== */
.comment-list {
    &__item {
        &:last-child .comment-list__content {
            border-bottom: none; 
          }
    }
    &__thumb {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        @include sm-screen {
            width: 60px;
            height: 60px;
        }
    }
    &__content {
        margin-left: 30px;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 30px;
        margin-bottom: 30px;
        @include sm-screen {
            margin-left: 16px;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
        @include msm-screen {
            padding-bottom: 20px;
            margin-bottom: 20px;
            width: 100%;
        }
    }
    &__name {
        margin-bottom: 0px;
    }
    &__date {
        font-size: clampCal(15, 18);
    }
    &__desc {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: clampCal(15, 18);
    }
    &__reply {
        display: inline-block;
        color: hsl(var(--heading-color));
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.012em;

        &:hover {
            color: hsl(var(--main));
        }
    }

    // Second List Item
    .comment-list {
        margin-left: 70px;
        &.style-right {
            .comment-list__item {
                &:last-child .comment-list__content {
                    border-bottom: 1px solid hsl(var(--black) / .1);
                  }
            }
        }
        @include msm-screen {
            margin-left: 40px;
        }
    }
} 
/* ================================= Comment Css End =========================== */ 
