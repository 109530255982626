/* ======================= Counter Section Css Start ======================= */
.counter-six {
    background-color: hsl(var(--white));
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        background: var(--gray-100);
        z-index: -1;
    }
    &__inner{
        padding: clampCal(24, 60);
        border-radius: 6px;
        box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
    }
    &-item {
        &__icon {
            width: 65px;
            height: 65px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            display: flex;
            justify-content: center;
            align-items: center;
            @include msm-screen {
                width: 56px;
                height: 56px;
            }
        }
        &__number {
            @include msm-screen {
                @include font-20;
            }   
        }
        &__text {
            margin-top: 12px;
            @include msm-screen {
                font-size: 0.875rem !important;
            }   
        }
    }
}
   
/* ======================= Counter Section Css End ======================= */