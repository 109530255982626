/* =========================== Counter Five Start ================================ */
.counter-five-wrapper {
    @extend .counter-three-wrapper; 
}
.counter-five-item {
    gap: clampCal(16, 24);
    @include xsm-screen {
        flex-direction: column;
        justify-content: start;
    }
    &__icon {
        width: clampCal(64, 90);
        height: clampCal(64, 90);
        border: 1px solid var(--border-color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        flex-shrink: 0;
    }
    &__number {
        margin-bottom: 4px;
        font-family: var(--poppins-font );
    }
}
/* =========================== Counter Five End ================================ */