/* ========================== About Two Section Start ========================= */
.about-two {
    &__button {
        margin-top: clampCal(20, 60);
    }
    &__thumb {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 50%;
            background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 100%);
            left: 0;
            bottom: 0;
        }
    }
}

.about-two-info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: clampCal(16, 40);
    &__title {
        margin-bottom: 6px;
        font-weight: 500;
    }
    &__date {
        opacity: 0.7;
        color: hsl(var(--white));
        font-weight: 300;
    }   
}
/* ========================== About Two Section End ========================= */