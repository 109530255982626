/* ===================== Checkout Section Css Start ============================ */
.payment-method {
    background-color: var(--gray-100);
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    @include xsm-screen {
        padding: 12px;
        font-size: 12px;
    }
    .form-check-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            max-width: 56px;
        }
    }
}
.billing-list {
    &__item {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
            margin-top: 4px;
            padding-top: 16px;
            border-top: 1px solid var(--border-color);
            .amount {
                font-size: 22px;
            }
        }
    }
}
/* ===================== Checkout Section Css End ============================ */