/* =========================== Off canvas Css Start ========================= */
.common-offcanvas {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--gray-900);
    padding: 32px;
    height: 100vh;
    overflow-y: auto;
    z-index: 991;
    width: 400px;
    transform: translateX(100%);
    transition: .2s linear;
    &.active {
        transform: translateX(0);
    }
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #e0e0e0;
    }
    &::-webkit-scrollbar-thumb {
        background: #bab9b9;
        border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #949494;
    }

    .close-button {
        background-color: hsl(var(--white)/.1);
        color: hsl(var(--white));
        &:hover {
            background-color: hsl(var(--white)/.3);
        }
    }
}

.address-list {
    &__item {
        gap: 12px;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            .address-list {
                &__icon {
                    border-color: hsl(var(--white)/.6);
                }
            }
        }
    }   
    &__icon {
        width: 40px;
        height: 40px;
        @include font-14; 
        color: hsl(var(--white));
        border-radius: 50%;
        border: 1px solid hsl(var(--white)/.3);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        transition: .2s linear;
    }
    &__content {

    }
    &__text {
        color: var(--gray-200);
        display: block;
        margin-bottom: 6px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    a.address-list__text {
        &:hover {
            color: hsl(var(--main));
        }
    }
}
/* =========================== Off canvas Css End ========================= */