/* ============================ Testimonials Section Start =========================== */
.testimonials-three {
    &__box {
        border-radius: 6px;
        padding: clampCal(32, 60, 1199, 1399) clampCal(24, 50, 1199, 1399);
        position: relative;
        @media (max-width: 991px) {
            border: 2px solid var(--border-color);
        }
        @media (min-width: 992px) {
            padding-bottom: 140px;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: calc(100% + 33%);
                height: 100%;
                border: 2px solid var(--border-color);
                border-radius: inherit;
            }
        }
    }
    .testimonial-item.style-two {
        @media (max-width: 991px) {
            padding-bottom: 100px;
        }
    }



    .slick-slider {
        @media (min-width: 992px) { 
            position: unset;;
        }
    }
    .slick-arrow {
        left: clampCal(24, 50, 1199, 1399);
        border-radius: 5px;
        border: 1px solid var(--border-color);
        &::before {
            visibility: hidden;
            opacity: 0;
        }
        
        &:hover {
            border: 1px solid transparent;
            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .slick-initialized.slick-slider .slick-slide {
        padding: 0;
    }
    .slick-list.draggable {
        padding: 0 !important;
    }
}
/* ============================ Testimonials Section End =========================== */