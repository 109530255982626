/* ============================= Contact Top Section Css Start ======================= */
.contact-card {
    text-align: center;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    padding: 32px 70px;
    transition: .2s linear;
    @include xl-screen {
        padding: 24px 40px;
    }
    @include sm-screen {
        padding: 24px;
    }
    &:hover {
        background-color: hsl(var(--white));
        box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
        border-color: hsl(var(--white));
    }
    &__icon {
        height: 80px;
        width: 80px;
        background: var(--main-gradient);
        border-radius: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: clampCal(30, 40);
        color: hsl(var(--white));
        @include sm-screen {
            height: 60px;
            width: 60px;
        }
    }
    &__title {
        margin: 20px 0;
    }
    &__text {
        margin-bottom: 4px;
        .link {
            color: inherit;
            font-size: inherit;
            &:hover {
                color: hsl(var(--main));
                @extend .text-gradient; 
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.contact-map {
    iframe {
        height: clampCal(400, 650);
    }
}

/* ============================= Contact Top Section Css End ======================= */