/* ======================== Video popup Section Css Start =================== */
.video-popup {
    background-color: hsl(var(--white));
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        background-color: hsl(var(--body-bg));
        z-index: -1;
    }
    &__thumb {
        position: relative;
        min-height: 220px;
        border-radius: 5px;
        overflow: hidden;
        img {
            min-height: inherit;
        }
        span {
            display: none;
        }
    }
    &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--main-gradient);
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: hsl(var(--white)) !important;
        font-size: 26px;
        border-radius: 50%;
        transition: .2s linear;
        &.style-two {
          background: var(--gray-900);
          &:before { 
            background: var(--gray-900);
          }
        }
        &:hover {
            transform: translate(-50%, -50%) scale(1.05);
        }
        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: inherit;
            height: inherit;
            background: inherit;
            border-radius: 50%;
            -webkit-animation: pulse-border 1500ms ease-out infinite;
            animation: pulse-border 1500ms ease-out infinite;
            z-index: -1;
        }
    }
}

  @keyframes pulse-border {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
/* ======================== Video popup Section Css End =================== */