/* ======================== Banner Seven Start ============================== */

.banner-seven {
    position: relative;
    .filter--box {
        background-color: hsl(var(--black)/0.4);
        backdrop-filter: blur(5px);
        border-radius: 5px;
        padding: 24px;
        position: absolute;
        z-index: 1;
        right: 0px;
        top: 0;
        margin: 24px;
        @include msm-screen {
            margin: 16px;
            padding: 16px;
        }
    }
}

.google-map {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--border-color);
    iframe {
        min-height: 678px;
        width: 100%;
        margin-bottom: -10px;
    }
}
/* ======================== Banner Seven End ============================== */
