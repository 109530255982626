/* ======================= Property Section Css Start ================================== */
.property-type {
    background-color: hsl(var(--white));
}

.property-type-item {
    background-color: var(--gray-100);
    border-radius: 5px;
    padding: 40px 30px;
    transition: .2s linear;
    height: 100%;
    @include md-screen {
        padding: 30px 20px;
    }
    @media (min-width: 425px) and (max-width: 575px) {
        padding: 24px 12px;
    }
    &:hover {
        background-color: var(--gray-200);
        .property-type-item {
            &__icon {
                img {
                    transform: rotateY(180deg);
                }
            }
        }
    }
    &__icon {   
        width: 70px;
        height: 70px;
        background: var(--main-gradient);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        img {
            transition: .2s linear;
        }
    }
    &__title {
        margin: 20px 0;
        max-width: 220px;
        font-weight: 600;
        @include md-screen {
            margin: 12px 0;
        }
    }
    &__desc {
        max-width: 330px;
        @include md-screen {
            font-size: 1rem !important; 
        }
    }
}
/* ======================= Property Section Css End ================================== */