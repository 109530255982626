

/* ============================ Mixins Css Start ============================ */
/* Media Breakpoint for Each Device Start */
@mixin xxsm-screen {
  @media screen and (max-width: 374px) {
    @content;
  }
}

// Xtra Small
@mixin xsm-screen {
  @media screen and (max-width: 424px) {
    @content;
  }
}
// Medium Small Screen (max-width: 575)
@mixin msm-screen {
  @media screen and (max-width: 575px) {
    @content;
  }
}

//Small Screen (max-width: 767)
@mixin sm-screen {
  @media screen and (max-width: 767px) {
    @content;
  }
}

//Large Screen (max-width: 991)
@mixin md-screen {
  @media screen and (max-width: 991px) {
    @content;
  }
}

//Xtra Large Screen (max-width: 1199)
@mixin lg-screen {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

//Xtra Large Screen i (max-width: 1399)
@mixin xl-screen {
  @media screen and (max-width: 1399px) {
    @content;
  }
}

//Xtra Large Screen ii (max-width: 1499)
@mixin xxl-screen {
  @media screen and (max-width: 1499px) {
    @content;
  }
}
//Xtra Large Screen ii (max-width: 1599)
@mixin xxxl-screen {
  @media screen and (max-width: 1599px) {
    @content;
  }
}
/* Media Breakpoint for Each Device End */

/* Font Size For responsive devices Start */
@mixin font-10 {
  font-size: 0.625rem;
}
@mixin font-11 {
  font-size: 0.6875rem;
}
@mixin font-12 {
  font-size: 0.75rem;
}
@mixin font-13 {
  font-size: 0.8125rem;
}
@mixin font-14 {
  font-size: 0.875rem;
}
@mixin font-15 {
  font-size: 0.9375rem;
}
@mixin font-16 {
  font-size: 1rem;
}
@mixin font-17 {
  font-size: 1.0625rem;
}
@mixin font-18 {
  font-size: 1.125rem;
}
@mixin font-19 {
  font-size: 1.1875rem;
}
@mixin font-20 {
  font-size: 1.25rem;
}
@mixin font-21 {
  font-size: 1.3125rem;
}
@mixin font-22 {
  font-size: 1.375rem;
}
@mixin font-23 {
  font-size: 1.4375rem;
}
@mixin font-24 {
  font-size: 1.5rem;
}
@mixin font-25 {
  font-size: 1.5625rem;
}
@mixin font-26 {
  font-size: 1.625rem;
}
@mixin font-27 {
  font-size: 1.6875rem;
}
@mixin font-28 {
  font-size: 1.75rem;
}
@mixin font-29 {
  font-size: 1.8125rem;
}
@mixin font-30 {
  font-size: 1.875rem;
}
@mixin font-31 {
  font-size: 1.9375rem;
}
@mixin font-32 {
  font-size: 2rem;
}
@mixin font-33 {
  font-size: 2.0625rem;
}
@mixin font-34 {
  font-size: 2.125rem;
}
@mixin font-35 {
  font-size: 2.1875rem;
}
@mixin font-36 {
  font-size: 2.25rem;
}
@mixin font-37 {
  font-size: 2.3125rem;
}
@mixin font-38 {
  font-size: 2.375rem;
}
@mixin font-39 {
  font-size: 2.4375rem;
}
@mixin font-40 {
  font-size: 2.5rem;
}
/* Font Size For responsive devices End */

/* ============================ Mixins Css End ============================ */
