/* ======================= Service Slider Section Css Start ======================= */
.service-slider {
    border: 1px solid var(--gray-800);
    padding: 40px;
    border-right: 0;
    border-left: 0;
    .js-marquee {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    &__item {
        min-width: max-content;
    }
}
/* ======================= Service Slider Section Css Ends ======================= */