

/* ================ Top Header Start Here ================ */ 
.header-top{
    padding: 11px 0;
    &-two {
        background-color: hsl(var(--white));
    }
    &__inner {
        padding: 11px 0;
        border-bottom: 1px solid var(--gray-100);
    }
}

.header-info {
    gap: clampCal(12, 36);
    @include msm-screen {
        width: 100%;
        justify-content: center;
    }
    &__item {
        color: hsl(var(--heading-color));
        gap: 10px;
        @include font-18; 
        @include md-screen {
            @include font-16; 
        }
        @include sm-screen {
            @include font-15; 
        }
    }
    &__icon {
        @include font-16; 
        @include md-screen {
            @include font-14; 
        }
        @include sm-screen {
            @include font-13; 
        }
    }
    &__text {
        color: hsl(var(--heading-color));
        &:hover {
            color: hsl(var(--main));
        }
    }
}


.contact-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__item {
        padding-right: 20px;        
        margin-right: 20px;        
        position: relative;
        &:last-child {
            padding-right: 0;
            margin-right: 0;
            &::before {
                display: none;
            }
        }
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 15px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: hsl(var(--white) /.4);
        }
        &-icon {
            @include font-12; 
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: hsl(var(--white));
            background-color: hsl(var(--main));
            border-radius: 50%;
            margin-right: 10px;
            display: inline-block;
        }
    }
    &__link {
        color: hsl(var(--white));
        font-weight: 400;
        font-family: var(--body-font);
        &:hover &-icon {
            color: hsl(var(--white));
        }
    }
}
/* Login Registration */
.login-registration-list {
    margin: 0 -10px;
    padding-right: 20px;
    @include md-screen {
        padding-right: 0;
    }
    &__item {
        color: hsl(var(--white));
        padding: 0 10px;
        position: relative;
        &:last-child::before {
            display: none;
        }
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 12px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: hsl(var(--white)/.5);
        }
    }
    &__icon {
        color: hsl(var(--main));
        margin-right: 10px;
        @include font-16; 
    }
   &__link {
        color: hsl(var(--white));
        font-weight: 400;
        font-family: var(--body-font);
        @include msm-screen {
            @include font-15; 
        }
        &:hover {
            color: hsl(var(--main));
            .login-registration-list__icon {
                color: hsl(var(--white));
            }
            @include md-screen {
               color: hsl(var(--main));
            }
        }
    }
}

/* Language Box */
.language-box {
    display: flex;
    align-items: center;
    position: relative;
    .select {
        color: hsl(var(--white));
        background-color: transparent;
        border: 1px solid hsl(var(--white)/0.4);
        padding: 5px 8px;
        @include font-15; 
        font-weight: 400;
        border-radius: 5px;
        option {
            background-color: hsl(var(--black));
        }
    }
}
/* ================ Top Header End Here ================ */
