
/* ==================================== Floor Plan Section Css Start ========================== */
.floor-plan {
    &__title {
        position: relative;
        padding-right: 184px;
        @media (min-width: 992px) and (max-width: 1200px) {
            padding-right: 100px;
        }
        @include msm-screen {
            padding-right: 100px;
        }
        @include xsm-screen {
            padding-right: 0;
        }
        &::before {
            position: absolute;
            content: "";
            width: 168px;
            height: .1px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: hsl(var(--white)/.4);
            @media (min-width: 992px) and (max-width: 1200px) {
                    width: 110px;
            }
            @include msm-screen {
                width: 80px;;
            }
            @include xsm-screen {
                display: none;
            }
        }
    }
    &-list {
        &__item {
            font-weight: 400;
            font-size: clampCal(17, 20);
            border-bottom: 1px solid hsl(var(--white)/.1);
            padding: 20px 0;
            @include sm-screen {
                padding: 12px 0;
            }
            &:first-child {
                padding-top: 30px;
                margin-top: 30px;
                border-top: 1px solid hsl(var(--white)/.1);
                @include sm-screen {
                    padding-top: 20px;
                    margin-top: 20px;
                }
            }
            &:last-child {
                border-bottom: 0;
                padding-top: 30px;
                @include sm-screen {
                    padding-top: 20px;
                }
            }
    
            .number {
                font-weight: inherit;
            }
        }
    }
    &__desc {
        color: hsl(var(--white)/.5);
    }   
}
/* ==================================== Floor Plan Section Css End ========================== */