
/* =========================================== Blog Details Css Start ==============================*/
.blog-details {
    &__thumb {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
    }
    &__date {
        padding: 10px 20px;
        border-radius: 0 0 0 5px;
        background: var(--main-gradient);
        color: hsl(var(--white));
        @include font-18; 
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
    &__content {
        padding: clampCal(16, 30);
        padding-top: 0;
        background-color: var(--gray-100);
        max-width: 740px;
        border-radius: 0 5px 5px 5px;
        margin-top: -110px;
        position: relative;
        @include sm-screen {
            margin-top: 20px;
            border-radius: 5px;
        }
    }
    &__title {
        margin-top: 30px;
        margin-bottom: 20px;
        @include md-screen {
            margin-top: 20px;
            margin-bottom: 12px;
        }
    }
    &__desc {
        margin-bottom: 20px;
        font-size: clampCal(16, 18);
        &:last-child {
            margin-bottom: 0;
        }
    }
}


.blog-infos {
    padding: 15px 12px;
    background: var(--main-gradient);
    display: inline-flex;
    align-items: center;
    border-radius: 0 0 5px 5px ;
    gap: clampCal(8, 30);
    &__item {
        color: hsl(var(--white));
        font-weight: 400;
        @include font-16; 
        @include sm-screen {
            @include font-14; 
        }
        @include msm-screen {
            @include font-12; 
        }
        @media (max-width: 360px) {
            @include font-10; 
        }
    }
    &__link {
        color: inherit;
        transition: .2s;
        font-size: inherit;
        display: flex;
        align-items: center;
        gap: 8px;
        &:hover {
            color: hsl(var(--heading-color));
        }
    }
}

/* Blog Testi Start */
.blog-testi {
    border: 2px solid var(--border-color);
    border-radius: 5px;
    padding: clampCal(16, 30);
    margin:  clampCal(20, 40) 0; 
    &__thumb {
        width: 45px;
        height: 45px;
        border-radius: 5px;
        overflow: hidden;
    }
    &__desc {
        margin: 20px 0;
        font-size: clampCal(16, 18);
    }
    &__name {
        font-weight: 600;
        position: relative;
        padding-left: 50px;
        font-family: var(--poppins-font);
        &::before {
            position: absolute;
            content: "";
            width: 40px;
            height: 2px;
            background: var(--main-gradient);
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
    }
}
/* Blog Testi End */

/* Blog ShowCase Start */
.blog-showcase {
    &__content {
        gap: 30px;
        margin-bottom: 20px;
    }
    &__desc {
        margin-bottom: clampCal(24, 40);
        font-size: clampCal(16, 18);
    }
}

.check-list {
    &.style-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        @include lg-screen {
            grid-gap: 12px;
        }
        @include xsm-screen {
            grid-template-columns: 1fr;
        }
        .check-list {
            &__item {
                margin-bottom: 0;
                &:nth-child(3), &:nth-child(4) {
                    margin-left: clampCal(16, 50, 1199, 1399);
                    margin-bottom: 0;
                    @include xsm-screen {
                        margin-left: 24px;
                    }
                }
                .text {
                    @include font-14; 
                    @include lg-screen {
                        @include font-12; 
                    }
                }
            }
        }
    }
    &__item {
        margin-bottom: 20px;
        gap: 12px;
        @include sm-screen {
            margin-bottom: 12px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            color: hsl(var(--white));
            background: var(--main-gradient);
            border-radius: 50%;
            flex-shrink: 0;
        }
        .text {
            color: hsl(var(--heading-color));
            font-weight: 500;
            font-family: var(--poppins-font);
            font-size: clampCal(14, 18);
        }
    }
}
/* Blog ShowCase End */

/* Blog NextPrev Start */
.blog-nextPrev {
    &__item {
        gap: clampCal(16, 30);
    }
    &__button {
        width: clampCal(40, 60);
        height: clampCal(40, 60);
        border: 1px solid var(--border-color);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: clampCal(15, 18);
        &:hover {
            border-color: hsl(var(--main));
            @extend .text-gradient; 
        }
    }   
    &__text {
        margin-bottom: 4px;
        font-size: clampCal(15, 18);
    }
}
/* Blog NextPrev End */

/* Blog Keyword Start */
.blog-keyword {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: clampCal(16, 24) clampCal(16, 40);
    margin:  clampCal(20, 40) 0; 
    &__link {
        color: var(--gray-800);
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            color: hsl(var(--main)); 
            @extend .text-gradient; 
        }
    }
}
/* Blog Keyword End */

/* Comment Form Start */
.comment-form {
    padding: clampCal(24, 60);
    border-radius: 5px;
    box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
    background-color: hsl(var(--white));
    &__title {
        margin-bottom: clampCal(16, 24);
        font-family: var(--poppins-font);
    }   
    &__desc {
        margin-bottom: clampCal(20, 30);
    }
}
/* Comment Form End */
/* ========================================== Blog Details Css End ======================================*/