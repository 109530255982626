/* ======================= Project page Section Css Start ====================== */
.project-page {
    &-thumb {
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        height: 100%; 
        &:hover {
            .project-page-content {
                height: 100%;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &-content {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: clampCal(16, 24);
        background-color: hsl(var(--black)/.5);
        border-radius: inherit;
        height: 20%;
        visibility: hidden;
        opacity: 0;
        transition: .2s linear;
        &__title {
            color: hsl(var(--white));
            margin-bottom: 0;
            .link {
                color: inherit;
                &:hover {
                    @extend .text-gradient; 
                }
            }
        }
    }
}
/* ======================= Project page Section Css End ====================== */

