/* ================================= preload Css Start =========================== */
.preloader {
  background-color: hsl(var(--white));
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}
.preloader .loader {
  background: hsl(var(--main));
  border-radius: 50%;
  width: 70px;
  height: 70px;
  left: 50%;
  margin-left: -23px;
  margin-top: -14px;
  position: absolute;
  top: 50%;
  animation: load 0.75s linear infinite;
}
.preloader .loader:after {
  content: '';
  position: absolute;
  width: 69px;
  height: 69px;
  top: 3px;
  right: 0;
  left: 0;
  margin: auto;
  background: hsl(var(--white));
  border-radius: 50%;
}
@keyframes load {
  to {
    transform: rotate(360deg);
  }
}
/* ================================= preload Css End ===========================  */
