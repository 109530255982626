
/* ===================== Scroll to Top Start ================================= */
  .progress-wrap {
    position: fixed;
    right: 36px;
    bottom: 36px;
    height: 46px;
    line-height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    background-color: var(--gray-100);
    @include md-screen {
      right: 24px;
      bottom: 24px;
      height: 40px;
      width: 40px;
      line-height: 40px;
    }
    &.active-progress {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &:hover {
      transform: scale(1.06);
    }
    &::after {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f062';
        text-align: center;
        height: 46px;
        width: 46px;
        line-height: 46px;
        font-size: 18px;
        left: 0;
        top: 0;
        cursor: pointer;
        display: block;
        z-index: 1;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        color: hsl(var(--main)) !important;
        @include md-screen {
          height: 40px;
          width: 40px;
          line-height: 40px;
        }
    }
  }
  
  .progress-wrap svg path {
    fill: none;
  }
  .progress-wrap svg.progress-circle path {
    stroke: hsl(var(--main));
    stroke-width: 5;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
/* ===================== Scroll to Top End ================================= */
