/* ============================= CTA section End ===================== */
.cta-box {
    padding: clampCal(24, 95) clampCal(24, 80);
    border: 1px solid var(--border-color);
    border-radius: 5px;
}
.cta-content {
    flex-grow: 1;
    &__title {
        margin-bottom: 24px;
        @include md-screen {
            margin-bottom: 16px;
        }
    }   
    &__desc {
        margin-bottom: clampCal(24, 40);
        max-width: 570px;
        font-size: clampCal(16, 18);
        color: var(--gray-800);
    }
    &__form {
        max-width: 662px;
        @include xsm-screen {
            display: block !important; 
        }
        .btn-main {
            @include xsm-screen {
                width: 100%;
                margin-top: 16px;
            }
            .text {
                @include msm-screen {
                    display: none;
                }
            }
        }
    }
    &__thumb {

    }
}
/* ============================= CTA section End ===================== */