/* ========================= Counter Three Css Start ============================= */
.counter-three {
    background-color: var(--gray-900);
    z-index: 1;
    position: relative;
    overflow: hidden;
    @media (min-width: 992px) {
        padding-bottom: 240px;
    }
    &__bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        opacity: .2;
    }
    &-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 24px;
        @include md-screen {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.counter-three-item {
    color: hsl(var(--white)/.7);
    gap: 20px;
    justify-content: center;
    &:hover {
        .counter-three-item__icon::before {
            visibility: hidden;
            opacity: 0;
        }
    }
    @include xsm-screen {
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
    &.style-two {
        .counter-three-item {
            &__icon {
                border-radius: 5px;
                width: 110px;
                height: 90px;
                &::before {
                    display: none;
                }
                @include md-screen {
                    height: 90px;
                    width: 90px;
                }
                @include sm-screen {
                    height: 60px;
                    width: 60px;
                }
                img {
                    @include sm-screen {
                        max-width: 40px;
                    }   
                }
            }
        }
    }
    &__icon {
        position: relative;
        border-radius: 50%;
        width: clampCal(60, 80);
        height: clampCal(60, 80);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background: var(--main-gradient);
       
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--gray-900);
            z-index: -1;
            border-radius: inherit;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            transition: .2s linear;
        }
    }
    &__number {
        margin-bottom: 4px;
        color: hsl(var(--white)/.7);
    } 
}

/* ========================= Counter Three Css End ============================= */