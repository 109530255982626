/* ====================== property Type Five Css Start =============================== */
.property-type-five-item {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  @media (min-width: 425px) and (max-width: 575px) {
    display: flex;
  }
  &:hover {
    background-color: hsl(var(--white) / 0.04);
    &::before {
      width: 100%;
    }
    .property-type-five-item {
      &__thumb {
        img {
          transform: scale(1.04);
        }
      }
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 0%;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: var(--main-gradient);
    transition: 0.2s linear;
  }
  &__thumb {
    max-height: 320px;
    overflow: hidden;
    img {
      transition: 0.2s linear;
    }
  }
  &__content {
    padding: clampCal(20, 40);
    background-color: hsl(var(--white));
  }
  &__date {
    width: 70px;
    height: 70px;
    font-size: 28px;
    border-radius: 5px;
    background-color: hsl(var(--black) / 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--poppins-font);
    font-weight: 400;
    color: hsl(var(--heading-color));
    @include md-screen {
      width: 54px;
      height: 54px;
      font-size: 24px;
    }
  }
  &__title {
    margin-top: clampCal(16, 32);
    margin-bottom: clampCal(12, 20);
  }
  &__desc {
  }
}
/* ====================== property Type Five Css End =============================== */
