/* ======================= Services Section Start ========================= */
.service-item {
    background-color: var(--gray-700);
    border-radius: 5px;
    padding: clampCal(20, 40);
    &__icon {
        width: clampCal(68, 88);
        height: clampCal(68, 88);
        border-radius: 5px;
        border: 1px solid hsl(var(--white)/.2);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 55px;
        }
    }
    &__title {
        margin: 20px 0 24px;
        font-weight: 400;
        @include lg-screen {
            margin: 20px 0 16px;
        }
    }
    &__text {
        margin-bottom: clampCal(20, 55);
    }
}
/* ======================= Services Section End ========================= */