/* ================================= Range Slider Css Start =========================== */
.custom--range__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}

.custom--range__content label {
    width: 40%;
}

.custom--range__content input {
    width: 60%;
    text-align: right;
}

.custom--range input {
    border: 0;
    color: hsl(var(--body-color));
    font-weight: 400;
    outline: none;
}

.custom--range #slider-range {
    height: 5px;
    border: 0;
    background: hsl(var(--main)/0.15);
}

.custom--range #slider-range .ui-widget-header {
    background-color: hsl(var(--main));
    transition: .2s linear;
}

.custom--range #slider-range span:focus {
    background-color: hsl(var(--main));
}

.custom--range #slider-range .ui-slider-handle {
    width: 18px !important;
    height: 18px !important;
    background-color: hsl(var(--main)) !important;
    border: 2px solid hsl(var(--white)) !important;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: .2s linear;
    outline: none;
    &:hover, &:active {
    transform: translateY(-50%) scale(1.4);
    }
}

/* ================================= Range Slider Css End =========================== */