/* ========================= Filter Css Start ====================== */
.filter {
    padding: 30px;
    background-color: hsl(var(--white));
    box-shadow: 0px 4px 13px -2px rgba(19, 16, 34, 0.06), 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.10);
    border-radius: 5px;
    z-index: 1;
    @include sm-screen {
        padding: 20px;
    }

}
.filter--box {
    max-width: 360px;
}
/* ========================= Filter Css End ====================== */