/* ================================= Pagination Css Start =========================== */
.common-pagination {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
    @include lg-screen {
        margin-top: 48px;
    }
    @include md-screen {
        margin-top: 40px;
    }
    @include sm-screen {
        margin-top: 32px;
    }
    .page-item {
        &.active {
            .page-link {
                color: hsl(var(--white));
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        .page-link {
            border: 0;
            margin: 0 5px;
            border-radius: 5px;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent ;
            font-weight: 500;
            padding: 0;
            color: hsl(var(--heading-color));
            @include font-20; 
            @include md-screen {
                width: 48px;
                height: 48px;
            }
            &::before, &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: var(--gray-100);
                transition: 0.2s linear;
                border-radius: inherit;
            }
            &::after {
                background: var(--main-gradient);
                visibility: hidden;
                opacity: 0;
            }
            &:hover, &:focus, &:focus-visible {
                color: hsl(var(--white));
                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}
/* ================================= Pagination Css End =========================== */