
/* ============= Footer Start Here ======================= */
.footer {
    margin-top: auto;
    background-color: hsl(var(--black));
}


.footer-item {
    &__desc {
        margin-top: 32px;
        max-width: 230px;
        color: hsl(var(--white));
        @include font-18; 
        font-weight: 300;
    }
    &__logo {
        margin-bottom: 20px;
        a {
            img {
                width: 100%;
                height: 100%;
                max-width: 190px;
                max-height: 64px;
            }
        }
    }
    &__title {
        margin-bottom: clampCal(16, 40);
        font-family: var(--poppins-font);
        color: hsl(var(--white));
        font-weight: 500;
    }
}
/* Footer List Item */
.footer-menu {
    display: flex;
    flex-direction: column;
    &__item {
        display: block;
        padding-bottom: 16px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    &__link {
        color: hsl(var(--white));
        @include font-18; 
        font-weight: 300;
        @include md-screen {
            @include font-16; 
        }
        &:hover {
            color: hsl(var(--main));
        }
    }
}


.gallery-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    max-width: 240px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__item {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        &:hover {
            .gallery-list__link {
                transform: scale(1);
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &__link {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: hsl(var(--black)/.6);
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0; 
        left: 0;
        color: hsl(var(--white));
        transform: scale(.4);
        visibility: hidden;
        opacity: 0;
        &:hover {
            color: hsl(var(--main));
        }
    }
}

/* Footer Contact */
.footer-contact {
    &__item {
        gap: 16px;
        color: hsl(var(--white));
        font-weight: 300;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__icon {
        font-size: inherit;
        font-weight: inherit;
    }
    &__text {
        font-size: inherit;
        font-weight: inherit;
    }
    &__link {
        color: inherit;
        &:hover {
            color: hsl(var(--main)); 
            @extend .text-gradient; 
        }
    }
}

/* Footer Top */
.footer-top {
    margin-bottom: clampCal(40, 80);
    &__left {
        display: flex;
        align-items: center;
        gap: clampCal(24, 40);
        .text {
            max-width: 330px;
        }
    }
    &__right {

    }
}

.subscribe-form {
    max-width: 550px;
    input {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        padding-left: 40px !important;
        color: hsl(var(--white));
        @media (min-width: 768px) {
            min-width: 400px;
        }
        &::placeholder {
            color: hsl(var(--white)/.8);
        }
    }
    .input-icon {
        left: 0 ;
    }
}
/* ============= Footer End Here ======================= */

/* ============= Bottom Footer End Here ======================= */
.bottom-footer {
    background-color: hsl(var(--black));
    &__inner {
        border-top: 1px solid hsl(var(--white)/.15);
        padding: 30px 0;
        @include md-screen {
        padding: 20px 0;
        }
    }
    &__text {
        @extend .footer-link; 
    }
}
.footer-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: clampCal(10, 30);
}
.footer-link {
    font-weight: 400;
    @include font-18; 
    font-weight: 300;
    color: hsl(var(--white)/.8);
    @include md-screen {
        @include font-16; 
    }
    @include sm-screen {
        @include font-15; 
    }
    &:hover {
        color: hsl(var(--main));
    }
}
/* =============Bottom Footer End Here ======================= */

