/* =============================== Star Rating Css Start =============================== */
.star-rating {
    gap: 5px;
    &__item {
        @extend .text-gradient; 
        @include font-20; 
        &.unabled {
            background: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--gray-200);
        }
    }
}
/* =============================== Star Rating Css End =============================== */