/* ================================= Blog Section Css Start Here ============================= */ 
.blog-item {
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    &.blog-dark {
        .blog-item {
            &__content {
                border: 0;
                background-color: var(--gray-700);
            }        
            &__title {
                margin-bottom: 36px;
                @include md-screen {
                    margin-bottom: 24px;
                }
                &-link {
                    color: hsl(var(--white));
                }
            }
            &__date {
                font-size: 1rem;
                font-weight: 400;
                border-radius: 50px;
                padding: 4px 12px;
                right: 12px;
                top: 12px;
            }
        }
    }

    &:hover {
        .blog-item {
            &__thumb {
                img {
                    transform: scale(1.06);
                }
            }
        }
    }
    &__thumb {
        max-height: 230px;
        overflow: hidden;
        position: relative;
        &-link {
            width: 100%;
            height: 100%;
            img {
                transition: .2s linear;
            }
        }
    }
    &__inner {
        position: relative;
    }
    &__date {
        padding: 6px 12px;
        border-radius: 5px;
        background-color: hsl(var(--white));
        position: absolute;
        right: clampCal(16, 30, 992, 1299);
        top: -30px;
        color: hsl(var(--heading-color));
        font-weight: 500;
        font-family: var(--poppins-font);
        font-size: clampCal(16, 28);
        line-height: 24px;

        &.style-three {
            left: 10px;
            top: 10px;
            text-align: center;
            right: auto;
            background-color: var(--gray-100);
            padding: 10px 20px;
        }
        
        .text {
            color: hsl(var(--body-color));
            font-family: var(--body-font);   
            font-size: clampCal(14, 18);
            font-weight: 300;
            display: block;
        }
    }
    &__content {
        padding: clampCal(16, 30, 992, 1299);
        padding-top: 30px;
        border: 1px solid var(--border-color);
        border-top: 0;
        border-radius: 0 0 5px 5px;
    }
    &__title {
        margin-top: 20px;
        @include msm-screen {
            margin-top: 15px;
        }
        &-link {
            color: hsl(var(--heading-color));
            overflow:hidden;
            text-overflow:ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-weight: 500;
            &:hover {
                @extend .text-gradient; 
            }
        }
    }
    &__desc {
        margin-bottom: 25px;
        overflow:hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include sm-screen {
            margin-bottom: 15px;
        }
    }
}

/* Text List */
.text-list {
    gap: 20px;
    border: 1px solid var(--border-color);
    padding: 12px 20px;
    border-radius: 5px;
    display: inline-flex;
    @include md-screen {
        padding: 8px 12px;
        gap: 10px;
    }
    &__item {
        .icon {
            color: hsl(var(--heading-color));
            margin-right: 8px;
        }
        .link {
            color: hsl(var(--body-color));
            &:hover {
                @extend .text-gradient; 
            }
        }
    }
}
/* ================================= Blog Section Css End Here ================================= */