/* ========================== Banner Two Css Start ========================== */
.banner-two {
    padding: 45px 0;
    padding-bottom: 0;
    &__title {
        font-size: clamp(2rem, -0.0733rem + 6.3692vw, 3.75rem);;
        font-family: var(--poppins-font);
        font-weight: 200;
        max-width: 530px;
        .text {
            font-weight: 500;   
            display: inline;
        }
    }
    &__filter {
        padding: clampCal(20, 60);
        margin-top: clampCal(32, 60);
        border-radius: 5px;
    }
}

.contact-content {
    max-width: 420px;
    &__box {
        border: 1px solid var(--border-color);
        padding: clampCal(24, 62) clampCal(24, 50);
        border-radius: 5px;
        margin-top: clampCal(24, 48);
    }
    &__desc {
        font-size: clampCal(16, 20);
        line-height: 34px;
        margin-bottom: clampCal(24, 38);
    }
    &__icon {
        max-width: 55px;
        &.style-bg {
            max-width: unset;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: hsl(var(--white)/.1);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__contact {
        font-size: clampCal(16, 20);
        margin-top: 2px;
        font-weight: 600;
        color: hsl(var(--white));
        display: block;
        &:hover {
            color: hsl(var(--main)); 
            @extend .text-gradient; 
        }
    }
}
/* ========================== Banner Two Css End ========================== */