/* ======================== About Section Start ========================== */
.about {
    .section-heading {
        margin-bottom: 30px;
    }
    &-button {
        margin-top: clampCal(16, 40);
    }
    &-thumb {
        position: relative;
        z-index: 1;
        text-align: center;
        padding: clampCal(32, 60);
        max-width: 580px;
        @media (min-width: 992px) {
            margin-right: clampCal(24, 50, 992, 1599);
        }
        &::before, &::after {
            position: absolute;
            content: "";
            width: clampCal(100, 170, 424, 768);;
            height: 100%;
            background: var(--main-gradient);
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 5px;
            z-index: -1;
            opacity: .2;
        }
        &::after {
            left: auto;
            right: 0;
        }
        img {
            max-width: 460px;
            width: 100%;
            margin: 0 auto;
        }
    }
}

.about-box {
    border-radius: 5px;
    border: 2px solid var(--gray-200);
    padding: 20px;
    gap: 20px;
    &__icon {
        flex-shrink: 0;
    }
}

.client-statistics {
    background-color: hsl(var(--white));
    padding: 20px 40px;
    border-radius: 5px 5px 0 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    gap: clampCal(12, 20);
    @include lg-screen {
        padding: 20px 16px;
    }
    @media (max-width: 1199px) {
        padding: 20px 16px;
    }
    @media (max-width: 991px) {
        padding: 20px 40px;
    }
    @media (max-width: 480px) {
        padding: 20px;
    }
    @media (max-width: 424px) {
        padding: 12px;
    }
    &__icon {
        font-size: clampCal(20, 40, 424, 1199);
    }
    &__content {
        text-align: start;
    }
    &__number {
        margin-bottom: 0;
        font-family: var(--poppins-font);
        font-weight: 500;
        margin-bottom: 5px;
    }
}
/* ======================== About Section End ========================== */