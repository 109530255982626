
/* ====================== Section Heading ==================== */
.section-heading {
  text-align: center;
  margin-bottom: clampCal(40, 72);
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  &.style-dark {
    .section-heading {
      &__subtitle {
        background-color: var(--gray-700);
      }
      &__title {
        color: hsl(var(--white));
      }
    }
  }
  &.style-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: unset;
    align-items: center;
    .section-heading {
      &__inner {
        max-width: 630px;
      }
      &__desc {
        max-width: 410px;
        @media (min-width: 1199px) {
          margin-left: auto !important;
        }
      }
    }
  }
  &__subtitle {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: hsl(var(--white));
    margin-bottom: 10px;
    text-transform: uppercase;
    @include font-14; 
    letter-spacing: 0.11em;
  }
  &__title {
    position: relative;
    margin-bottom: 0;
  }
  &__desc {
    margin-top: clampCal(16, 32);
    font-size: clampCal(16, 18);
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
  // Style left
  &.style-left {
    text-align: left;
    margin-left: 0;
    .section-heading {
      &__desc {
        margin-left: 0;
      }
    }
  }
}
/* ====================== Section Heading En d==================== */
