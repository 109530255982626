/* ====================== Banner Four Css Start ============================== */
.banner-four {
    position: relative;
    z-index: 1;
    @media (min-width: 576px) {
        padding: 144px 0;
        padding-bottom: 100px;
    }
    &__thumb {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        max-width: 90%;
        @include msm-screen {
            display: none;
        }
    }
    &__inner {
        background-color: hsl(var(--white));
        mask-image: url(../images/shapes/banner-four-shape.png);
        mask-repeat: no-repeat;
        mask-size: contain;
        @include md-screen {
            mask-image: unset;
            border-radius: 0 1000px 1000px 0;
        }
        @include msm-screen {
            border-radius: 0;
            background-color: var(--gray-100);
        }
    }
}

.banner-four-content {
    padding: 80px 0;
    max-width: 635px;
    @include sm-screen {
        max-width: 435px;
    }
    @include msm-screen {
        padding: 60px 0;
    }
    &__subtitle {
        background-color: var(--gray-100);
        border-radius: 50px;
        padding: 5px 10px;
        color: hsl(var(--heading-color));
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.11em;
        @include font-14; 
    }
    &__title {
        font-family: var(--poppins-font);
        font-weight: 600;
        font-size: clampCal(32, 70);
        margin-top: 20px;
        margin-bottom: clampCal(24, 56);
    }
}

.search-keyword {
    .common-input {
        padding-right: 200px;
        @include sm-screen {
            padding-right: 152px;
        }
    }
    .btn {
        font-weight: 400;
        font-size: 21px;
        line-height: 24px;
        padding: 16px 46px;    
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        @include lg-screen {
            padding: 14px 32px; 
        }
        @include sm-screen {
            padding: 11px 20px; 
        }
        @include xxsm-screen {
            position: relative;
            top: 0;
            transform: unset;
            width: 100%;
            right: 0;
            margin-top: 10px;
        }
    }
}

/* Banner Shapes */
.banner-four-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    max-width: 45%;
    @include msm-screen {
        display: none;
    }
    &.one {
        left: 54%;
        top: auto;
        bottom: 15%;
        animation: rotationScale 8s linear infinite; 
    }
    &.two {
        left: 38%;
        animation: leftRight 20s linear infinite;
    }
    &.three {
        bottom: 0%;
        top: auto;
        left: 27%;
        animation: leftRight 20s linear infinite;
    }
}

@keyframes leftRight {
    0%, 100% {
        transform: translate(0px);
    }
    50% {
        transform: translate(-300px);
    }
}
/* ====================== Banner Four Css End ============================== */