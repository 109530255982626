/* ============================= Counter Section Css Start ======================= */
.counter-item {
    text-align: center;
    &__number {
        position: relative;
        font-family: var(--poppins-font);
        opacity: 0.05;
        font-weight: 700;
        line-height: 0.91;
        font-size: clampCal(50, 90);
        display: inline-block;
        margin-bottom: 0;
    }   
    &__text {
        color: hsl(var(--heading-color));
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: clampCal(12, 18, 768, 1199);
        width: 100%;
        text-align: center;
    }
}
/* ============================= Counter Section Css End ======================= */