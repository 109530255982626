/* ======================= Property Type Three Start =========================== */
.property-type-three-item {
    border: 1px solid var(--border-color);
    padding: clampCal(16, 30);
    border-radius: 5px;
    gap: clampCal(12, 24);
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: .2s linear;
    @media (min-width: 425px) and (max-width: 768px) {
        flex-direction: column;
    }
    &:hover {
        background-color: hsl(var(--white));
        box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
        &::before {
            width: 100%;
        }
    }
    &::before {
        position: absolute;
        content: "";
        width: 0%;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        background: var(--main-gradient);
        transition: .2s linear;
    }
    &__icon {
        max-width: 55px;
    }
    &__desc {
        margin: 20px 0;
        @include md-screen {
            margin: 12px 0;
        }
    }
}
/* ======================= Property Type Three End =========================== */