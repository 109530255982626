/* ================================= Cart Page Css Start ============================= */
.cart-item {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid hsl(var(--black)/.08);
    @include md-screen {
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
    &__thumb {
        width: 100px;
        height: 60px;
        border-radius: 6px;
        overflow: hidden;
    }
    &__title {
        margin-bottom: 6px;
        .link {
            width: 250px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
    }
    &__price {
        @include font-14; 
    }
    &__count {
        width: auto;
        display: inline-flex;
        align-items: center;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        margin: 0 auto;
        input {
            background: transparent;
            border: 0;
            text-align: center;
            width: 24px;
            color: hsl(var(--body-color));
            font-size: 14px;
            &:focus {
                outline: none;
            }
        }        
        button {
            width: 34px;
            height: 34px;
            font-size: 13px;
            color: hsl(var(--body-color));
            border-radius: inherit;
            &:hover {
                color: hsl(var(--heading-color));
                background-color: var(--gray-100);
            }
        }
    }
}

.rounded-btn {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: .2s linear;
    @include font-14; 
    margin-left: auto;
    &:hover {
        transform: scale(1.1);
    }
}
/* ================================= Cart Page Css End ============================= */