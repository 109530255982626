/* ======================== Blog Classic Css Start ============================ */
.blog-classic-item {
    height: auto;
    margin-bottom: clampCal(24, 60);
    @include sm-screen {
        display: block !important;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .blog-item {
        &__thumb {
            max-height: 406px;
            max-width: 270px;
            flex-shrink: 0;
            @include sm-screen {
                max-width: 100%;
                max-height: 300px;
            }
        }
        &__content {
            border-radius: 0 5px 5px 0;
            padding: 40px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            @include lg-screen {
                padding: 32px 20px;
            }
            @include md-screen {
                padding: 24px 16px;
            }
            @include sm-screen {
                border-radius: 0 0 5px 5px;
            }
        }
        &__title {
            margin: 20px 0;
            @include md-screen {
                margin: 8px 0;
            }
        }
        &__desc {
            margin-bottom: clampCal(20, 40);
            @include sm-screen {
                font-size: 0.9375rem !important;
            }
        }
        &__date {
            background-color: var(--gray-900);
            padding: 5px 6px;
            top: 0;
            right: 0;
            color: hsl(var(--white));
            font-weight: 400;
            border-radius: 0 0 0 5px;
        }
    }
}
/* ======================== Blog Classic Css End ============================ */