/* ============================= Message Two Section Css Start ======================== */
.message-two {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 77%;
        background-color: var(--gray-700);
        left: 0;
        top: 0;
        z-index: -1;
    }
}
/* ============================= Message Two Section Css End ======================== */