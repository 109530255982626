/* ========================== Account Page Css Start ==================== */

p.account-alert {
    padding: 20px;
    background-color: hsl(var(--main)/.1);
    border: 1px solid hsl(var(--main)/.14);
    border-radius: 5px;
    margin-bottom: 16px;
    background-color: #0294021c;
    border-color: #02940230;
    &:last-child {
        margin-bottom: 0;
    }
}

.profile-info {
    &__thumb {
        max-width: 180px;
        max-height: 180px;
        border-radius: 5px;
        overflow: hidden;
    }
}
/* ========================== Account Page Css End ==================== */