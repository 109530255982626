/* ================================ Testimonials Section Css Start ============================= */
.testimonial {
    .slick-slide {
        transform: scale(.9);
        transition: .3s linear !important;
        padding: 0 !important;
        &.slick-active {
            transform: scale(1);
        }
    }
    &__inner {
        @extend .message; 
    }
    &-thumb {
        @extend .message-thumb; 
        border-radius: 5px;
        left: 50px;
        right: 0;
        text-align: end;
        width: auto
    }
    &-box {
        @extend .form-box; 
        margin-left: 0;
    }
}

.testimonial-item {
    position: relative;
    overflow: hidden;
    &.style-two {
        background-color: hsl(var(--white));
        padding: clampCal(20, 40);
        border-radius: 5px;
        .testimonial-item {
            &__info {
                gap: 20px;
            }
            &__thumb {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }
    &.style-four {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 5px;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            background: var(--main-gradient);
            transition: 0.2s linear;
        }
        .testimonial-item {
            &__quote {
                @extend .text-gradient; 
                font-size: clampCal(60, 124);
                opacity: 0.1;
                line-height: 1;
                position: absolute;
                bottom: 20px;
                right: clampCal(20, 40);;
            }
        }
    }    
    &__name {
        margin-bottom: 5px;
    }
    &__designation {
        font-family: var(--poppins-font);
    }
    &__desc {
        margin-top: 32px;
        margin-bottom: 50px;
        font-size: clampCal(18, 22);
        @include sm-screen {
            margin-top: 20px;
            margin-bottom: 24px;
        }
    }
}
/* ================================ Testimonials Section Css End ============================= */