/* Font Family*/
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&family=Vidaloka&display=swap');

/* ========================= Variable Css Start ======================== */
:root {    

  /* Font Family Variable */
  --heading-font: 'Vidaloka', serif;
  --body-font: 'Jost', sans-serif;
  --poppins-font: 'Poppins', sans-serif;
  
  /* Font Size Variable Start */
  --heading-one: clamp(2rem, -0.0733rem + 6.3692vw, 4.25rem); // Min:32px - Max:68px
  --heading-two: clamp(1.75rem, 0.7133rem + 2.8846vw, 2.875rem); // Min: 28px - Max: 46px
  --heading-three: clamp(1.5rem, 0.3353rem + 2.1661vw, 2.5rem); // Min: 24px - Max: 40px
  --heading-four: clamp(1.25rem, 0.5569rem + 1.444vw, 2rem); // Min: 20px - Max: 32px
  --heading-five: clamp(1.125rem, 1.2rem + 0.722vw, 1.75rem); // Min: 18px - Max: 28px
  --heading-six: clamp(1rem, 0.769rem + 0.6813vw, 1.25rem); // Min: 16px - Max-20px
  /* Font Size End */

  /* Color Variables Start */
  --main-gradient: linear-gradient(90deg, #F69220 0.04%, #F68E20 28.67%, #F48421 54.05%, #F37221 78.11%, #F05A22 100.04%);
  --main-gradient-rev: linear-gradient(270deg, #F69220 0.04%, #F68E20 28.67%, #F48421 54.05%, #F37221 78.11%, #F05A22 100.04%);

 /* Gray Color */
  --gray-h: 0;
  --gray-s: 0%;
  --gray-l: 97%;
  --gray: var(--gray-h) var(--gray-s) var(--gray-l);
  
  --gray-900: #181616;
  --gray-800: #777; 
  --gray-700: #211E1E; 
  --gray-200: #E0E0E0; 
  --gray-100: #F8F8F8; 

  /* White Color */
  --white: 0 0% 100%;
  
  /* Light Color */
  --light-h: 0;
  --light-s: 1%;
  --light-l: 53%;
  --light: var(--light-h) var(--light-s) var(--light-l);

  /* Black Color */ 
  --black-h: 0;
  --black-s: 4%;
  --black-l: 9%;
  --black: var(--black-h) var(--black-s) var(--black-l);
  
  --heading-color: var(--black);
  --body-color: var(--light);
  --body-bg: var(--gray);
  --border-color: var(--gray-200);
  --section-bg: 208 100% 97%;

  /* Card box shadow */
  --box-shadow: 0px 2px 15px hsl(var(--black) /.05);
  --box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.15), 0px 4px 13px -2px rgba(19, 16, 34, 0.1);;

  /* ========================= Main Color ============================= */
  --main-h: 28;
  --main-s: 91%;
  --main-l: 54%;
  --main: var(--main-h) var(--main-s) var(--main-l);
  
  /* Main Lighten */
  --main-l-100: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.1);
  --main-l-200: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.2);
  --main-l-300: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.3);
  --main-l-400: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.4);
  --main-l-500: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.5);
  --main-l-600: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.6);
  --main-l-700: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.7);
  --main-l-800: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.8);
  --main-l-900: var(--main-h) calc(var(--main-s)) calc(var(--main-l) + (100% - var(--main-l)) * 0.9);

  /* Main Darken  */
  --main-d-100: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.1);
  --main-d-200: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.2);
  --main-d-300: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.3);
  --main-d-400: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.4);
  --main-d-500: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.5);
  --main-d-600: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.6);
  --main-d-700: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.7);
  --main-d-700: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.7);
  --main-d-800: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.8);
  --main-d-900: var(--main-h) var(--main-s) calc(var(--main-l) - var(--main-l) * 0.9);
  /* ========================= Main Two Color ============================= */
}

/* ========================= Variable Css End ======================== */
