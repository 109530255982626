/* ==================== Latest Properties Four Start ========================= */
.latest-properties-four {
    &__thumb {
        border-radius: 15px;
        overflow: hidden;
    }
    &__item {
        gap: clampCal(16, 36);
        margin-bottom: clampCal(24, 32);
    }
    &__icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: hsl(var(--white));
        font-size: 20px;
    }
    &__title {
        margin-bottom: 12px;
    }
}
/* ==================== Latest Properties Four End ========================= */