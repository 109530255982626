/* ============================ Latest property Css Start ==================== */
.property-filter {
  margin-bottom: clampCal(24, 60);
  &__bottom {
    margin-top: clampCal(20, 30);
  }
}

/* List & Grid button */
.list-grid {
  display: none !important; 
  @media (min-width: 360px) { 
    display: flex !important; 
    &__button {
      width: 40px;
      height: 40px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      font-size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s linear;
      &.active {
        border-color: hsl(var(--main)) !important;
        background: hsl(var(--main)) !important;
        color: hsl(var(--white)) !important;
      }
      &:hover {
        background-color: var(--gray-100);
      }
    }
  }
}
/* List & Grid button */

.property {
  background-color: var(--gray-900);
  .section-heading {
    max-width: unset;
    &__inner {
      max-width: 620px;
    }
  }
  &__btn {
    margin-top: clampCal(24, 60);
  }
}


/* property Item Start */
.property-item {
  background-color: hsl(var(--white) / 0.025);
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  position: relative;
  transition: 0.2s linear;
  box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1),
    0px 4px 13px -2px rgba(19, 16, 34, 0.06);

  @media screen and (min-width: 425px) and (max-width: 575px) {
    display: flex;
  }
  &.style-white {
    .property-item {
      &__badgeFour {
        background-color: hsl(var(--white));
        position: absolute;
        right: 0;
        top: 0;
        padding: 4px 12px;
        border-radius: 0 0 0 5px;
        letter-spacing: 0.11em;
      }
      &__bottom {
        border-color: var(--border-color);
      }
    }
  }
  &:hover {
    background-color: hsl(var(--white) / 0.04);
    &::before {
      width: 100%;
    }
    .property-item {
      &__thumb {
        img {
          transform: scale(1.04);
        }
      }
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 0%;
    height: 3px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: var(--main-gradient);
    transition: 0.2s linear;
  }
  &__thumb {
    position: relative;
    overflow: hidden;
    max-height: 252px;
    display: flex;
    .link {
      display: block;
      width: 100%;
    }
    img {
      transition: 0.2s linear;
    }
  }
  &__badge {
    position: absolute;
    right: 0;
    top: 0;
    background: var(--main-gradient);
    padding: 5px 10px;
    border-radius: 0px 0px 0px 5px;
    color: hsl(var(--white));
    z-index: 1;
    text-transform: uppercase;
    @include font-14;
    letter-spacing: 2px;
  }
  &__content {
    padding: clampCal(16, 30);
    color: hsl(var(--white));
    flex-grow: 1;
  }
  &__price {
    margin-bottom: clampCal(12, 16);
    .day {
      @include font-12;
      opacity: 0.8;
      font-weight: 300;
    }
  }
  &__title,
  &__price {
    color: hsl(var(--white));
    font-family: var(--poppins-font);
    font-weight: 400;
  }
  &__title {
    margin-bottom: clampCal(12, 20);
    .link {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        @extend .text-gradient;
      }
    }
  }
  &__location {
    font-size: clampCal(14, 18);
    font-weight: 300;
    opacity: 0.8;
    @include msm-screen {
      @include font-12;
    }
    .icon {
      @include font-14;
      @include msm-screen {
        @include font-11;
      }
    }
  }
  &__bottom {
    padding-top: 16px;
    margin-top: 22px;
    border-top: 1px solid hsl(var(--white) / 0.1);
  }
}

.property-item {
  &.style-two {
    overflow: unset;
    display: block;
    background-color: transparent;
    box-shadow: none;
    &::before {
      display: none;
    }
    .property-item {
      &__thumb {
        border-radius: 5px;
        overflow: hidden;
      }
      &__content {
        box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1),
          0px 4px 13px -2px rgba(19, 16, 34, 0.06);
        border-radius: 5px 0px 5px 5px;
        margin-top: -88px;
        margin-left: 40px;
        position: relative;
        background-color: hsl(var(--white));
        @include lg-screen {
          margin-top: -88px;
          margin-left: 20px;
          padding: 16px;
        }
      }
      &__title,
      &__price {
        color: hsl(var(--heading-color));
        font-weight: 600;
      }
      &__price {
        margin-top: 20px;
      }
      &__location {
        color: var(--gray-800);
        font-weight: 400;
        margin-bottom: 24px;
      }
    }
    .amenities-list {
      &__item {
        .text {
          color: var(--gray-800);
          font-weight: 500;
        }
      }
    }
  }
}
/* property Item End */


/* Property List Grid Wrapper */
.list-view {
  @media (min-width: 360px) {
    .list-grid-item-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      &.show-two-item {
        @media (min-width: 992px) {
          grid-template-columns: 1fr 1fr;
        }
      }
      > div {
        width: 100%;
      }
    }
    .property-item {
      display: flex;
    }
    .property-item__content {
      margin-top: 0 !important;
      margin-left: 0 !important;
      border-radius: 0 5px 5px 0 !important;
    }
    .property-item__thumb {
      border-radius: 5px 0 0 5px !important;
      display: flex !important;
      max-height: unset !important;
      max-width: 374px !important; 
      @include msm-screen {
        max-width: 160px !important;  
      }
      @include xsm-screen {
        max-width: 120px !important;  
      }
    }
  }
}
/* Property List Grid Wrapper */

/* Amenities Css */
.amenities-list {
  gap: 20px;
  &__item {
    gap: 10px;
    @include font-12;
    opacity: 0.8;
    font-weight: 300;
    .icon {
      @include font-16;
    }
  }
}

.simple-btn {
  font-size: clampCal(12, 14, 1199, 1399);
  color: hsl(var(--white));
  text-transform: uppercase;
  opacity: 0.9;
  &:hover {
    @extend .text-gradient;
    > span {
      margin-left: 5px;
    }
  }
  .icon-right {
    margin-left: 10px;
  }
  > span {
    transition: 0.2s linear;
  }
}
/* ============================ Latest property Css End ==================== */
