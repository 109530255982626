
/* ======================= WordPress Default Css Start =================== */
.admin-bar .default-header .sticky-wrapper.sticky {
    top: 32px;
  }
  
  .wp-block-search__label {
    position: relative;
    font-size: 24px;
    font-weight: 500 !important;
    font-family: var(--title-font);
    line-height: 1em;
    margin: -0.12em 0 25px 0;
  }
  
  p.has-drop-cap {
    margin-bottom: 20px;
  }
  
  .page--item p:last-child .alignright {
    clear: right;
  }
  
  .blog-title,
  .pagi-title,
  .breadcumb-title {
    word-break: break-word;
  }
  
  .blocks-gallery-caption,
  .wp-block-embed figcaption,
  .wp-block-image figcaption {
    color: var(--body-color);
  }
  
  .bypostauthor,
  .gallery-caption {
    display: block;
  }
  
  .page-links,
  .clearfix {
    clear: both;
  }
  
  .page--item {
    margin-bottom: 30px;
  }
  .page--item p {
    line-height: 1.8;
  }
  .page--item .th-comment-form {
    padding: 0;
  }
  .page--item .th-comments-wrap {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
  
  .content-none-search {
    margin-top: 30px;
  }
  
  .wp-block-button.aligncenter {
    text-align: center;
  }
  
  .alignleft {
    display: inline;
    float: left;
    margin-bottom: 10px;
    margin-right: 1.5em;
  }
  
  .alignright {
    display: inline;
    float: right;
    margin-bottom: 10px;
    margin-left: 1.5em;
    margin-right: 1em;
  }
  
  .aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  
  .gallery {
    margin-bottom: 1.5em;
    width: 100%;
  }
  
  .gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    padding: 0 5px;
  }
  
  .wp-block-columns {
    margin-bottom: 1em;
  }
  
  figure.gallery-item {
    margin-bottom: 10px;
    display: inline-block;
  }
  
  figure.wp-block-gallery {
    margin-bottom: 14px;
  }
  
  .gallery-columns-2 .gallery-item {
    max-width: 50%;
  }
  
  .gallery-columns-3 .gallery-item {
    max-width: 33.33%;
  }
  
  .gallery-columns-4 .gallery-item {
    max-width: 25%;
  }
  
  .gallery-columns-5 .gallery-item {
    max-width: 20%;
  }
  
  .gallery-columns-6 .gallery-item {
    max-width: 16.66%;
  }
  
  .gallery-columns-7 .gallery-item {
    max-width: 14.28%;
  }
  
  .gallery-columns-8 .gallery-item {
    max-width: 12.5%;
  }
  
  .gallery-columns-9 .gallery-item {
    max-width: 11.11%;
  }
  
  .gallery-caption {
    display: block;
    font-size: 12px;
    color: var(--body-color);
    line-height: 1.5;
    padding: 0.5em 0;
  }
  
  .wp-block-cover p:not(.has-text-color),
  .wp-block-cover-image-text,
  .wp-block-cover-text {
    color: var(--white-color);
  }
  
  .wp-block-cover {
    margin-bottom: 15px;
  }
  
  .wp-caption-text {
    text-align: center;
  }
  
  .wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;
  }
  .wp-caption .wp-caption-text {
    margin: 0.5em 0;
    font-size: 14px;
  }
  
  .wp-block-media-text,
  .wp-block-media-text.alignwide,
  figure.wp-block-gallery {
    margin-bottom: 30px;
  }
  
  .wp-block-media-text.alignwide {
    background-color: var(--smoke-color);
  }
  
  .editor-styles-wrapper .has-large-font-size,
  .has-large-font-size {
    line-height: 1.4;
  }
  
  .wp-block-latest-comments a {
    color: inherit;
  }
  
  .wp-block-button {
    margin-bottom: 10px;
  }
  .wp-block-button:last-child {
    margin-bottom: 0;
  }
  .wp-block-button .wp-block-button__link {
    color: #fff;
  }
  .wp-block-button .wp-block-button__link:hover {
    color: #fff;
    background-color: var(--theme-color);
  }
  .wp-block-button.is-style-outline .wp-block-button__link {
    background-color: transparent;
    border-color: var(--title-color);
    color: var(--title-color);
  }
  .wp-block-button.is-style-outline .wp-block-button__link:hover {
    color: #fff;
    background-color: var(--theme-color);
    border-color: var(--theme-color);
  }
  .wp-block-button.is-style-squared .wp-block-button__link {
    border-radius: 0;
  }
  
  ol.wp-block-latest-comments li {
    margin: 15px 0;
  }
  
  ul.wp-block-latest-posts {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
  }
  ul.wp-block-latest-posts a {
    color: inherit;
  }
  ul.wp-block-latest-posts a:hover {
    color: var(--theme-color);
  }
  ul.wp-block-latest-posts li {
    margin: 15px 0;
  }
  
  .wp-block-search {
    display: flex;
    flex-wrap: wrap;
  }
  .wp-block-search .wp-block-search__inside-wrapper {
    border: 1px solid #EAEBEE;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
  }
  .wp-block-search .wp-block-search__input {
    width: 100%;
    max-width: 100%;
    padding-left: 20px;
    border: 0;
  }
  .wp-block-search .wp-block-search__button {
    margin: 0;
    min-width: 110px;
    border: none;
    color: #fff;
    border-radius: 10px;
    background-color: var(--theme-color);
  }
  .wp-block-search .wp-block-search__button.has-icon {
    min-width: 55px;
  }
  .wp-block-search .wp-block-search__button:hover {
    background-color: var(--title-color);
  }
  
  .wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper {
    padding: 0;
    border: none;
  }
  .wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input {
    padding: 0 8px 0 25px;
  }
  
  ul.wp-block-rss a {
    color: inherit;
  }
  
  .wp-block-group.has-background {
    padding: 15px 15px 1px;
    margin-bottom: 30px;
  }
  
  .wp-block-table td,
  .wp-block-table th {
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .wp-block-table.is-style-stripes {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .wp-block-table.is-style-stripes {
    border: 0;
    margin-bottom: 30px;
    border-bottom: 0;
  }
  .wp-block-table.is-style-stripes th, .wp-block-table.is-style-stripes td {
    border-color: var(--border-color);
  }
  
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .btn {
    top: 32px;
  }
  @media (max-width: 782px) {
    .logged-in .will-sticky .sticky-active.active,
    .logged-in .preloader .btn {
      top: 46px;
    }
  }
  @media (max-width: 600px) {
    .logged-in .will-sticky .sticky-active.active,
    .logged-in .preloader .btn {
      top: 0;
    }
  }
  
  .post-password-form {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .post-password-form p {
    display: flex;
    position: relative;
    gap: 15px;
    /* Extra small devices */
  }
  @media (max-width: 575px) {
    .post-password-form p {
      flex-wrap: wrap;
    }
  }
  .post-password-form label {
    display: flex;
    align-items: center;
    flex: auto;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 0;
    gap: 15px;
    /* Extra small devices */
  }
  @media (max-width: 575px) {
    .post-password-form label {
      flex-wrap: wrap;
    }
  }
  .post-password-form input {
    width: 100%;
    border: none;
    height: 55px;
    padding-left: 25px;
    color: var(--body-color);
    border: 1px solid var(--border-color);
  }
  .post-password-form input[type=submit] {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    width: 140px;
    border: none;
    color: #fff;
    background-color: var(--theme-color);
    text-align: center;
  }
  .post-password-form input[type=submit]:hover {
    background-color: var(--title-color);
  }
  
  .page-links {
    clear: both;
    margin: 0 0 1.5em;
    padding-top: 1em;
  }
  .page-links > .page-links-title {
    margin-right: 10px;
  }
  .page-links > span:not(.page-links-title):not(.screen-reader-text),
  .page-links > a {
    display: inline-block;
    padding: 5px 13px;
    background-color: var(--white-color);
    color: var(--title-color);
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin-right: 10px;
  }
  .page-links > span:not(.page-links-title):not(.screen-reader-text):hover,
  .page-links > a:hover {
    opacity: 0.8;
    color: var(--white-color);
    background-color: var(--theme-color);
    border-color: transparent;
  }
  .page-links > span:not(.page-links-title):not(.screen-reader-text).current,
  .page-links > a.current {
    background-color: var(--theme-color);
    color: var(--white-color);
    border-color: transparent;
  }
  .page-links span.screen-reader-text {
    display: none;
  }
  
  .blog-single .wp-block-archives-dropdown {
    margin-bottom: 30px;
  }
  .blog-single.format-quote, .blog-single.format-link, .blog-single.tag-sticky-2, .blog-single.sticky {
    position: relative;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    background-color: var(--smoke-color);
    border: none;
    padding: 40px;
    border-radius: 15px;
  }
  .blog-single.format-quote .blog-content:before, .blog-single.format-link .blog-content:before, .blog-single.tag-sticky-2 .blog-content:before, .blog-single.sticky .blog-content:before {
    display: none;
  }
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    content: "\f0c1";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-size: 5rem;
    opacity: 0.3;
    right: 15px;
    line-height: 1;
    top: 15px;
    color: var(--theme-color);
    z-index: 1;
  }
  .blog-single.tag-sticky-2::before, .blog-single.sticky::before {
    content: "\f08d";
    position: absolute;
    font-family: var(--icon-font);
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
    right: 0;
    top: 0;
    color: var(--white-color);
    background-color: var(--theme-color);
    z-index: 1;
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 0 15px 0 4px;
  }
  .blog-single.format-quote blockquote, .blog-single.format-quote .wp-block-quote {
    background: var(--white-color);
    margin-bottom: 0;
  }
  .blog-single.format-quote:before {
    content: "\f10e";
    top: 0;
  }
  .blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
  .blog-single .blog-content .wp-block-archives-dropdown {
    display: block;
    margin-bottom: 30px;
  }
  .blog-single.format-chat .entry-content > p:nth-child(2n) {
    background: var(--smoke-color);
    padding: 5px 20px;
  }
  
  .blog-details .blog-single:before {
    display: none;
  }
  .blog-details .blog-single .blog-content {
    background-color: transparent;
    overflow: hidden;
  }
  .blog-details .blog-single .blog-content p:last-child {
    margin-bottom: 0;
  }
  .blog-details .blog-single.format-chat .blog-meta {
    margin-bottom: 20px;
  }
  .blog-details .blog-single.format-chat .blog-content > p:nth-child(2n) {
    background: var(--smoke-color);
    padding: 5px 20px;
  }
  .blog-details .blog-single.tag-sticky-2, .blog-details .blog-single.sticky, .blog-details .blog-single.format-quote, .blog-details .blog-single.format-link {
    box-shadow: none;
    background-color: transparent;
  }
  .blog-details .blog-single.tag-sticky-2:before, .blog-details .blog-single.sticky:before, .blog-details .blog-single.format-quote:before, .blog-details .blog-single.format-link:before {
    display: none;
  }
  
  .blog-single .wp-block-tag-cloud {
    margin-bottom: 20px;
  }
  .blog-single .wp-block-tag-cloud a {
    background-color: var(--smoke-color);
    color: var(--title-color);
    box-shadow: none;
  }
  
  .th-search {
    background-color: #f3f3f3;
    margin-bottom: 30px;
    border: 1px solid #f3f3f3;
  }
  .th-search .search-grid-content {
    padding: 30px;
    /* Small devices */
  }
  @media (max-width: 767px) {
    .th-search .search-grid-content {
      padding: 20px;
    }
  }
  .th-search .search-grid-title {
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 0;
  }
  .th-search .search-grid-title a {
    color: inherit;
  }
  .th-search .search-grid-title a:hover {
    color: var(--theme-color);
  }
  .th-search .search-grid-meta > * {
    display: inline-block;
    margin-right: 15px;
    font-size: 14px;
  }
  .th-search .search-grid-meta > *:last-child {
    margin-right: 0;
  }
  .th-search .search-grid-meta a,
  .th-search .search-grid-meta span {
    color: var(--body-color);
  }
  
  /* Large devices */
  @media (max-width: 1199px) {
    .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
      font-size: 14px;
      padding: 8px 16px;
    }
    .blog-single.format-quote:before {
      top: 15px;
    }
  }
  /* Small devices */
  @media (max-width: 767px) {
    .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  @media (max-width: 768px) {
    .wp-block-latest-comments {
      padding-left: 10px;
    }
    .page--content.clearfix + .th-comment-form {
      margin-top: 24px;
    }
  }
  .site {
    overflow-x: visible;
  }
/* ======================= WordPress Default Css End =================== */