/* ========================== News Letter Section Start =============================== */
.newsletter {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: var(--gray-100);
    }
    &-content {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding: 100px 24px;
        z-index: 1;
        @include md-screen {
            padding: 80px 24px;
        }
        @include sm-screen {
            padding: 60px 24px;
        }
        @include msm-screen {
            padding: 40px 24px;
        }
        &::before, &::after{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(247, 144, 32, 0) 66.48%, rgba(247, 144, 32, 0.62) 123.98%);
            left: 0;
            top: 0;
            z-index: -1;
        }
        &::after {
            opacity: 0.7;
            background-color: var(--gray-900);
        }
        &__desc {
            max-width: 570px;
            margin: 0 auto;
            margin-bottom: clampCal(24, 40);
        }
        &__form {
            max-width: 595px;
            margin: 0 auto;
            .common-input {
                border-width: 2px;
                border-color: hsl(var(--white)/.6);
                color: hsl(var(--white));
                &::placeholder {
                    color: hsl(var(--white));
                    font-size: 16px !important;
                    font-weight: 400;
                }
            }
        }
    }
}

/* ========================== News Letter Section End =============================== */