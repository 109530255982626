
/* ====================== Breadcrumb Css Start ==================== */ 
.breadcrumb {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    background-color: var(--gray-900);
    padding: clampCal(80, 160) 0;
    &__wrapper {
        text-align: center;
    }
    &__img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: -1;
    }
    &__title {
        margin-bottom: 20px;
        color: hsl(var(--white));
        font-family: var(--poppins-font);
        font-size: clampCal(30, 68);
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    &__item{
        font-weight: 400;
        @include font-18; 
        color: hsl(var(--white));
        @include md-screen {
            @include font-16; 
        }
    }
    &__link {
        font-weight: inherit;
        color: hsl(var(--white));
        font-size: inherit;
        &:hover {
            color: hsl(var(--main)); ;
        }
    }
}
/* ====================== Breadcrumb Css End ==================== */
