/* ===================== Choose Us Css Start =========================== */
.choose-us {
  &__thumb {
    padding: 20px 40px;
    position: relative;
    z-index: 1;
    @include lg-screen {
        padding: 20px;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 110px;
      height: 74px;
      border-radius: 5px;
      background: var(--main-gradient);
      right: 0;
      top: 0;
      z-index: -1;
    }
    &::after {
      top: auto;
      bottom: 0;
      border-radius: 99px 5px 5px 99px;
    }
    &-inner {
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            width: calc(100% - 70px);
            height: 100%;
            top: -20px;
            left: 1px;
            border: 3px solid var(--gray-800);
            border-radius: 6px 0 0 0;
            border-right: 0;
            border-bottom: 0;
            z-index: -1;
        }
    }
    .img {
      border-radius: 200px 5px 5px 5px;
    }
  }
  &__button {
    margin-top: clampCal(20, 40);
  }
}
.pipe-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
/* ===================== Choose Us Css End =========================== */
