/* ================= Slick Arrow & Dots css Start ================ */ 
.slick-initialized.slick-slider {
    .slick-track {
        display: flex;
    }
    .slick-slide {
      cursor: grab;
        height: auto;
        padding: 0 10px;
        > div {
            height: 100%;
        }
    }
  }
  
  /* Slick Slider Arrow Style two */
  .slick-arrow {
    position: absolute;
    z-index: 1;
    border: none;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: .2s linear;
    bottom: clampCal(24, 60);
    left: clampCal(24, 60);
    > i {
      @extend .text-gradient; 
    }
    &::before, &::after{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: var(--main-gradient);
      z-index: -1;
      border-radius: inherit;
      opacity: .16;
      transition: .2s linear;
    }
    &::after {
      background-color: var(--gray-100);
      visibility: hidden;
      opacity: 0;
    }
  }
  .slick-arrow {
    &:hover {
      > i {
        color: hsl(var(--white));
        -webkit-text-fill-color: hsl(var(--white));
      }
      &::before {
        opacity: 1;
      }
    }
  }
  button.slick-next {
    transform: translateX(50px);
  }


  /* Arrow Style Two */
  .arrow-style-two {
    position: relative;
    .slick-arrow {
      left: auto;
      right: 0;
      bottom: calc(100% + 50px);
      width: 60px;
      height: 60px;
      border-radius: 5px;
      @include md-screen {
          
      }
      &:hover::after {
          visibility: hidden;
          opacity: 0;
      }
      &::before {
          opacity: 1;
      }
      &::after {
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          opacity: 1;
          visibility: visible;
          background: hsl(var(--white));
          transform: translate(1px, 1px);
      }
    }
    .slick-prev {
      right: 80px;
    }
    .slick-next {
      transform: translateX(0px);
    }
  }

  /* Dots Css Start */ 
  .slick-dots {
      text-align: center;
      padding-top: 20px;
      li {
          display: inline-block;
          button {
              border: none;
              background-color: var(--gray-800);
              color: hsl(var(--white));;
              margin: 0 3px;
              width: 8px;
              height: 8px;
              border-radius: 1px;
              border-radius: 50%;
              text-indent: -9999px;
              transition: .3s linear;
          }
          &.slick-active button {
              background: var(--main-gradient);
              width: 25px;
              border-radius: 5px;
          }
      }
  }
  /* Dots Css End */
  /* ================= Slick Arrow & Dots css Start ================ */ 
  