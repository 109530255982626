/* ====================== Footer Two Css Start ============================ */
.subscribe-box {
    input {
        border-color: hsl(var(--white)/.3);
    }
}

.footer-two {
    .footer-item {
        &__desc {
            max-width: 410px;
            color: hsl(var(--white));
            opacity: 0.8;
            @include lg-screen {
                max-width: 100%;
            }
        }
    }
}


.contact-info {
    &.style-two {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        .contact-info {
            &__icon {
                width: 48px;            
                height: 48px;
                background: var(--main-gradient);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: hsl(var(--white));
                font-size: 20px;
                @include md-screen {
                    width: 40px;            
                    height: 40px;
                }
            }
        }

    }
    &__text {
        color: hsl(var(--white));
        opacity: 0.8;
        font-size: clampCal(15, 18);
        font-weight: 300;
        display: block;
        margin-bottom: 4px;
    }
    &__address {
        font-size: clampCal(15, 18);
    }
}

.footer-menu  {
    &__link {
        position: relative;
        padding-left: 28px;
        &::before {
            position: absolute;
            content: "\f101";
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 13px;
            @extend .text-gradient; 
        }
    }
}
/* ====================== Footer Two Css End ============================ */