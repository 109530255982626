/* ======================= About five Css Start ========================= */
.about-five {
  &__thumbs { 
    position: relative;
  }
  &__thumb {
    margin-bottom: 24px;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
    &.one {
      border-radius: 99px 5px 99px 99px;
    }
    &.two {
      border-radius: 5px 99px 99px 99px;
    }
    &.three {
      border-radius: 99px 99px 5px 5px;
    }
  }

  &__content {
    border-radius: 53px 0px 53px 53px;
    background: var(--main-gradient);
    padding: 20px;
    gap: 10px;
    position: absolute;
    left: 50%;
    bottom: 12px;
    transform: translateX(-50%);
    min-width: max-content;
  }
  &__icon {
    width: 52px;
    height: 52px;
    background-color: hsl(var(--white)/.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsl(var(--white));
    font-size: clampCal(18, 20);
    flex-shrink: 0;
  }
  &__amount {
    font-weight: 500;
  }
  &__text {
    font-weight: 200;
  }
}
/* ======================= About five Css End ========================= */
