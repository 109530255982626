/* ============================= About Three Section Css Start =========================== */
.about-three-thumb {
    position: relative;
    @include md-screen {
        max-width: 520px;
    }
    &::before {
        position: absolute;
        content: "";
        width: 160px;
        height: 75px;
        background: var(--main-gradient);
        top: 6px;
        left: -40px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        transform: rotate(-45deg);
    }
    img {
        max-width: 520px;
        border-radius: 10px;
        width: 100%;
    }
}

.project-content {
    position: absolute;
    right: 0;
    bottom: clampCal(12, 30);
    display: inline-block;
    text-align: center;
    z-index: 1;
    @include md-screen {
        right: 10px;
    }
    &::before {
        position: absolute;
        content: "";
        background-image: url(http://imagineerblogs.files.wordpress.com/2011/09/172320.jpg);
        background-image: url(../images/shapes/polygon-border-shape.png);
        background-position: center top;
        background-size: cover;
        left: 0;
        top: 0;
        z-index: -1;
        width: 167px;
        height: 167px;
        @include md-screen {
            display: none;
        }
    }

    &__inner {
        padding: 52px 30px;
        background-color: var(--gray-100);
        -webkit-mask: url(./../images/shapes/polygon-shape.png) no-repeat 50% 50%;
        mask: url(./../images/shapes/polygon-shape.png) no-repeat 50% 50%;
        -webkit-mask-size: 156px;
        mask-size: 156px;
        @include md-screen {
            mask-size: 130px;
            padding: 41px 23px;
        }
    }
    &__number {
        margin-bottom: 6px;
    }
}
/* ============================= About Three Section Css End =========================== */