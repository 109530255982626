/* =========================== Banner Six Css Start =============================== */
.banner-video {
    padding: 140px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: hsl(var(--black)/.7);
    }
    &__video {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        object-fit: cover;
    }
}

.banner-video {
    &__subtitle {
        @extend .section-heading__subtitle; 
        background-color: hsl(var(--white)/.15) !important;
    }
    &__title {
        margin-top: 8px;
        margin-bottom: 32px;
    }
    &__desc {
        max-width: 600px;
        margin: 0 auto;
        font-size: 20px !important;
        font-weight: 300;
    }
}
/* =========================== Banner Six Css End =============================== */