/* ===================== Faq section Css Start ========================= */
.faq {
    position: relative;
    z-index: 1;
    @include md-screen {
        margin-top: 0;
    }
    &::before {
        position: absolute;
        content: "";
        width: 60%;
        height: 100%;
        background-color: hsl(var(--white));
        border-radius: 0 5px 0 0;
        left: 0;
        top: 0;
        z-index: -1;
        @include md-screen {
            width: 100%;
            border-radius: 0;
        }
    }
    &-thumb {
        position: absolute;
        right: 0;
        top: 120px;
        width: 48%;
        display: flex;
        height: calc(100% - 240px);
        border-radius: 5px;
        overflow: hidden;
    }
}
/* ===================== Faq section Css End ========================= */