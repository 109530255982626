.common-sidebar-wrapper {
    position: sticky;
    top: 120px;
}

.common-sidebar {
    background-color: var(--gray-100);
    padding: clampCal(20, 40, 1199, 1399);
    border-radius: 5px;
    margin-bottom: clampCal(24, 40);
    &:last-of-type {
        margin-bottom: 0;
    }
    &__title {
        position: relative;
        margin-bottom: clampCal(16, 30, 1199, 1399);
        font-family: var(--poppins-font);
    }
}
/* ========================= Category & Tags List Style ========================= */
.category-list {
    &__item {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__link {
        color: hsl(var(--heading-color));
        font-weight: 500;
        @include font-16; 
        border: 1px solid var(--border-color);
        padding: 10px 20px;
        border-radius: 5px;
        position: relative;
        padding-left: 64px;
        flex-wrap: nowrap;
        @include lg-screen {
            padding: 10px;
            padding-left: 32px;
        }
        &:hover {
            background-color: hsl(var(--main));
            border-color: hsl(var(--main));
            color: hsl(var(--white));
            .number {
                color: hsl(var(--white));
            }
        }
        &::before {
            position: absolute;
            content: "\f061";
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: inherit;
            @include font-12; 
            @include lg-screen {
                left: 12px;
            }
        }
        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .number {
            flex-shrink: 0;
            color: var(--gray-800);
            @include font-12; 
            transition: .2s linear;
        }
    }
}

.tag-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    &__link{
        color: hsl(var(--gray-300)); 
        border: 1px solid var(--border-color); 
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 400;
        @include font-18; 
        @include md-screen {
            @include font-18; 
        }
        &:hover {
            background-color: hsl(var(--main)); 
            border-color: hsl(var(--main)); 
            color: hsl(var(--white));
        }
    }

}
/* ======================== Latest Blog Css ========================  */
.latest-blog {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: hsl(var(--white));
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
    &:last-of-type {
        margin-bottom: 0px;
    }
    &__thumb {
        width: 82px;
        display: flex;
        overflow: hidden;
        @include xsm-screen {
            width: 60px;
        }
        a {
            display: block;
            height: 100%;
            width: 100%;
        }
    }
    &__content {
        width: calc(100% - 82px);
        padding: 20px 10px;
        background-color: hsl(var(--white));
        @include xsm-screen {
            width: calc(100% - 60px);
        }
    }
    &__title {
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 24px;
        a {
            color: hsl(var(--heading-color));
            font-weight: 600;
            @include font-16;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &:hover {
                color: hsl(var(--main)); 
            }
        }
    }
}
/* ========================= Latest Blog Css End ===================*/

/* ========================= Properties Css Start ===================*/
.properties-item {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &:hover {
        img {
            transform: scale(1.08);
        }
        .properties-item {
            &__text {
                @include font-13; 
            }
        }
    }
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(24, 22, 22, 0) 0%, #181616 100%);
        border-radius: inherit;
        z-index: 1;
    }
    img {
        transition: .2s linear
    }
    &__text {
        @include font-12; 
        font-weight: 400;
        color: hsl(var(--white));
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 10px 15px;
        z-index: 2;
        transform: scale(1.06);
    }
}


/* ========================= Properties Css End ===================*/
